<template>
  <div id="personal">
    <!-- 导航栏 -->
    <NavTop ref="navTop" pageName="personal"></NavTop>
    <!-- 个人主页壁纸 -->
    <div class="personal-bg">
      <div class="personal-bg-box">
        <img :src="personalInfo.backgroundWall" alt=""/>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content-l">
        <!-- 个人信息 -->
        <div class="content-l-user">
          <div class="user-avatar">
            <div class="user-avatar-mine" v-if="!authorId" @click="openEditDialog">
              <img :src="personalInfo.avatar" alt=""/>
              <span>编辑资料</span>
            </div>
            <div class="user-avatar-others" v-else>
              <img :src="personalInfo.avatar" alt=""/>
            </div>
          </div>
          <div class="user-name">{{ personalInfo.nickName }}</div>
          <div class="user-bar" v-if="!authorId">
            <span>首币：{{ balanceInfo.availableAmount }}</span>
            <div class="user-bar-button" @click="onRecharge">充值</div>
          </div>
          <div class="user-info">
            <div class="user-info-box">
              <div class="num">{{ personalInfo.popularity }}</div>
              <div class="text">人气</div>
            </div>
            <div class="user-info-box">
              <div class="num">{{ personalInfo.attentionNumber }}</div>
              <div class="text">关注</div>
            </div>
            <div class="user-info-box">
              <div class="num">{{ personalInfo.fansNumber }}</div>
              <div class="text">粉丝</div>
            </div>
          </div>
          <div class="user-autograph">
            <div class="tit">签名</div>
            <div class="text">{{ personalInfo.motto }}</div>
          </div>
        </div>
        <!-- 最近访客 -->
        <div class="content-l-visitor">
          <p class="visitor-tit">最近访客</p>
          <template v-if="personalInfo.visitorList && personalInfo.visitorList.length > 0">
            <div class="visitor-list">
              <div class="info" v-for="item in personalInfo.visitorList" :key="item.userId">
                <div class="info-avatar">
                  <img :src="item.avatar" alt=""/>
                </div>
                <p class="info-name">{{ item.nickName }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="content-r">
        <div class="content-r-tab">
          <div class="tab-list">
            <div class="tab" v-if="authorId" :class="tabIndex === 1?'selected':'unselected'" @click="selectTabIndex(1)">
              ta的作品
            </div>
            <div class="tab" v-if="!authorId && hasPerm('showings:my:works')" :class="tabIndex === 5?'selected':'unselected'" @click="selectTabIndex(5)">
              我的作品
            </div>
            <div class="tab" v-if="!authorId" :class="tabIndex === 3?'selected':'unselected'" @click="selectTabIndex(3)">
              我的下载
            </div>
            <div class="tab" v-if="!authorId" :class="tabIndex === 2?'selected':'unselected'" @click="selectTabIndex(2)">
              我的收藏
            </div>
            <div class="tab" v-if="!authorId" :class="tabIndex === 4?'selected':'unselected'" @click="selectTabIndex(4)">
              我的订单
            </div>
            <div class="tab" v-if="!authorId" :class="tabIndex === 6?'selected':'unselected'" @click="selectTabIndex(6)">
              我的充值
            </div>
          </div>
          <div class="tab-show">
            <div class="tab-show-path" v-show="tabIndex === 2">
              <span @click="backCollectionIndex()">我的收藏</span>
              <span v-show="favoritePath"> > {{ favoritePath }}</span>
            </div>
            <div class="tab-show-upload" v-show="tabIndex === 5 && hasPerm('showings:works:upload')">
              <div class="upload" v-show="!draftShow" @click="jumpUploadPage(0)">
                <div class="upload-icon">
                  <img src="@/assets/images/icons/upload_works.png" alt="">
                </div>
                <span>上传作品</span>
              </div>
              <div class="draft" v-show="draftShow">
                <div class="draft-back" @click="onBackWorks">返回我的作品</div>
                <div class="draft-text">
                  <div class="draft-text-refresh" @click="openDraftBox">
                    <span>刷新列表</span>
                    <div class="icon">
                      <img src="@/assets/images/btn/refresh.png" alt="">
                    </div>
                  </div>
                  <div class="draft-text-info">(草稿箱中的作品仅对自己可见)</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ta的作品 -->
        <div class="content-r-display" v-if="tabIndex === 1">
          <div class="display-works-list">
            <div
                class="works"
                v-for="item in hisWorksList"
                :key="item.materialInfoVO.materialId"
                @click="jumpDetailsPage(item.materialInfoVO.materialName,item.materialInfoVO.materialId)">
              <div class="works-img">
                <img :src="item.materialInfoVO.cover" alt=""/>
              </div>
              <div class="works-info">
                <div class="works-info-name">
                  {{ item.materialInfoVO.materialName }}
                </div>
              </div>
            </div>
          </div>
          <div class="display-pagination" v-if="hisWorksList.length > 0">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="9"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="hisWorksList.length === 0">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
        <!-- 我的收藏 -->
        <div class="content-r-display" v-show="tabIndex === 2">
          <!-- 收藏夹列表 -->
          <div class="display-favorites-list" v-show="collectionIndex === 1">
            <div class="collection-add" @click="openAddDialog">
              <div class="icon">
                <img src="../../assets/images/icons/add_collection.png" alt="" />
              </div>
              <p>创建收藏夹</p>
            </div>
            <div class="collection" v-for="item in favoritesList" :key="item.id" @click.stop="openFavorites(item)">
              <div class="collection-cover">
                <div class="collection-t">
                  <div class="collection-t-have" v-if="item.imgList[0]">
                    <img :src="item.imgList[0]" alt=""/>
                  </div>
                  <div class="collection-t-no" v-else>
                    <img src="../../assets/images/icons/img_collection.png" alt="" />
                    <p>暂无收藏</p>
                  </div>
                </div>
                <div class="collection-c">
                  <div class="collection-c-box"
                      :style="{
                      border: item.imgList[1] ? 'none' : '1px dashed #979797',
                    }"
                  >
                    <img
                        class="box-have"
                        v-if="item.imgList[1]"
                        :src="item.imgList[1]"
                        alt=""
                    />
                    <img
                        class="box-no"
                        v-else
                        src="../../assets/images/icons/img_collection.png"
                        alt=""
                    />
                  </div>
                  <div
                      class="collection-c-box"
                      :style="{
                      border: item.imgList[2] ? 'none' : '1px dashed #979797',
                    }"
                  >
                    <img
                        class="box-have"
                        v-if="item.imgList[2]"
                        :src="item.imgList[2]"
                        alt=""
                    />
                    <img
                        class="box-no"
                        v-else
                        src="../../assets/images/icons/img_collection.png"
                        alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                  class="collection-t-edit"
                  @click.stop="openModifyDialog(item)"
              >
                <img
                    src="../../assets/images/icons/favorites_edit.png"
                    alt=""
                />
              </div>
              <div class="collection-info">
                <p>{{ item.name }}</p>
                <p>收藏夹</p>
              </div>
              <div class="collection-info">
                <p>{{ item.createTime }}创建</p>
              </div>
            </div>
          </div>
          <!-- 收藏夹作品列表 -->
          <div class="display-favorites-works" v-show="collectionIndex === 2">
            <div
                class="works"
                v-for="item in worksList"
                :key="item.materialInfoVO.materialId"
                @click="
                jumpDetailsPage(
                  item.materialInfoVO.materialName,
                  item.materialInfoVO.materialId
                )
              "
            >
              <div class="works-img">
                <img :src="item.materialInfoVO.cover" alt=""/>
              </div>
              <div class="works-edit" @click.stop="openMoveDialog(item)">
                <img
                    src="../../assets/images/icons/favorites_edit.png"
                    alt=""
                />
              </div>
              <div class="works-info">
                <div class="works-info-name">
                  {{ item.materialInfoVO.materialName }}
                </div>
              </div>
            </div>
          </div>
          <div class="display-pagination" v-if="worksList.length > 0">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="9"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="worksList.length === 0 && collectionIndex === 2">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
        <!-- 我的下载 -->
        <div class="content-r-display" v-show="tabIndex === 3">
          <div class="display-download-list">
            <div
                class="download"
                v-for="(item,index) in downloadList"
                :key="index"
            >
              <div class="download-l">
                <div class="download-l-title">素材信息</div>
                <div class="download-l-info">
                  <div
                      class="info-img"
                      @click="
                      jumpDetailsPage(
                        item.materialInfoVO.materialName,
                        item.materialInfoVO.materialId
                      )
                    "
                  >
                    <img :src="item.materialInfoVO.cover" alt=""/>
                  </div>
                  <div class="info-text">
                    <p>{{ item.materialInfoVO.materialName }}</p>
                    <p>ID: {{ item.materialInfoVO.materialCode }}</p>
                  </div>
                </div>
              </div>
              <div class="download-r">
                <div class="download-r-explain">
                  <div class="explain-title">素材分类</div>
                  <div class="explain-text">
                    <p>{{ item.materialInfoVO.categoryName }}</p>
                  </div>
                </div>
                <div class="download-r-explain">
                  <div class="explain-title">素材类型</div>
                  <div class="explain-text">
                    <p>{{ item.materialInfoVO.downloadType }}</p>
                  </div>
                </div>
                <div class="download-r-explain">
                  <div class="explain-title">版权归属</div>
                  <div class="explain-text">
                    <p>SHOWINGS</p>
                    <p>首映式</p>
                  </div>
                </div>
                <div class="download-r-explain">
                  <div class="explain-title">下载时间</div>
                  <div class="explain-text">
                    <p>{{ item.downloadVO.downloadTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="display-pagination" v-if="downloadList.length > 0">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="3"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="downloadList.length === 0">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
        <!-- 我的订单 -->
        <div class="content-r-display" v-show="tabIndex === 4">
          <div class="display-download-list">
            <div
                class="download"
                v-for="(item,index) in orderList"
                :key="index"
            >
              <div class="download-l">
                <div class="download-l-title">素材信息</div>
                <div class="download-l-info">
                  <div class="info-img" @click="jumpDetailsPage(item.materialName,item.materialId)">
                    <img :src="item.cover" alt=""/>
                  </div>
                  <div class="info-text">
                    <p>{{ item.materialName }}</p>
                    <p>ID: {{ item.materialCode }}</p>
                  </div>
                </div>
              </div>
              <div class="download-r">
                <div class="download-r-explain">
                  <div class="explain-title">素材分类</div>
                  <div class="explain-text">
                    <p>{{ item.categoryName }}</p>
                  </div>
                </div>
                <div class="download-r-explain">
                  <div class="explain-title">订单状态</div>
                  <div class="explain-text">
                    <p class="stay" v-if="item.status === 0">未支付</p>
                    <p class="already" v-if="item.status === 3">已完成</p>
                    <p class="already" v-if="item.status === 4">超时关闭</p>
                  </div>
                </div>
                <div class="download-r-explain">
                  <div class="explain-title">下单时间</div>
                  <div class="explain-text">
                    <p>{{ item.createTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="display-pagination" v-if="orderList.length > 0">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="3"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="orderList.length === 0">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
        <!-- 我的作品 -->
        <div class="content-r-display" v-show="tabIndex === 5">
          <div class="display-works-list">
            <!-- 草稿箱 -->
            <div class="draft" v-if="!draftShow" @click="openDraftBox"
                 :style="{'border': draftCover?'none':'1px dashed #818181'}"
            >
              <div class="draft-img" v-if="draftCover">
                <img :src="draftCover" alt=""/>
              </div>
              <div class="draft-icon">
                <div class="icon">
                  <img src="@/assets/images/icons/draft_img.png" alt="">
                </div>
                <p class="name">草稿箱</p>
                <p class="num">（共{{draftWorksLength}}个作品）</p>
              </div>
            </div>
            <!-- 我的作品 -->
            <template v-if="!draftShow">
              <div class="works" v-for="item in mineWorksList" :key="item.id">
                <div class="works-img">
                  <img :src="item.cover" alt=""/>
                </div>
                <div class="works-info">
                  <div class="works-info-name">{{item.materialName}}</div>
                </div>
                <el-tooltip v-if="item.auditStatus === 2" effect="dark" :content="item.auditMsg" placement="top">
                  <div class="works-status">{{item.auditResult === 1?'审核已通过':item.auditResult === 2?'审核未通过':item.auditResult}}</div>
                </el-tooltip>
                <div class="works-status" v-if="item.auditStatus !== 2">{{item.auditStatus === 0?'未审核':item.auditStatus === 1?'审核中':item.auditStatus}}</div>
                <div class="works-more">
                  <img class="works-more-out" src="@/assets/images/btn/more_icon.png" alt="">
                  <img class="works-more-enter" src="@/assets/images/btn/more_icon2.png" alt="">
                  <div class="works-more-tabs">
                    <span v-if="hasPerm('showings:works:edit') && item.auditResult === 2" @click.stop="jumpUploadPage(item.id)">编辑</span>
                    <span v-if="hasPerm('showings:works:delete')" @click.stop="delworks(item.id)">删除</span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 草稿箱作品 -->
            <template v-if="draftShow">
              <div class="works" v-for="item in draftWorksList" :key="item.id">
                <div class="works-img">
                  <img :src="item.cover" alt=""/>
                </div>
                <div class="works-info">
                  <div class="works-info-name">{{item.materialName}}</div>
                </div>
                <div class="works-more">
                  <img class="works-more-out" src="@/assets/images/btn/more_icon.png" alt="">
                  <img class="works-more-enter" src="@/assets/images/btn/more_icon2.png" alt="">
                  <div class="works-more-tabs">
                    <span v-if="hasPerm('showings:works:edit')" @click.stop="jumpUploadPage(item.id)">编辑</span>
                    <span v-if="hasPerm('showings:works:push')" @click.stop="releaseWorks(item.id)">发布</span>
                    <span v-if="hasPerm('showings:works:delete')" @click.stop="delworks(item.id)">删除</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="display-pagination" v-if="(!draftShow && mineWorksList.length > 0) || (draftShow && draftWorksList.length > 0)">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="9"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="draftShow && draftWorksList.length === 0">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
        <!-- 我的充值 -->
        <div class="content-r-display" v-show="tabIndex === 6">
          <div class="display-recharge-list">
            <div class="record" v-for="(item,index) in rechargeList" :key="index">
              <div class="record-item">
                <div class="record-item-title">订单ID</div>
                <div class="record-item-info">ID: {{item.orderNo}}</div>
              </div>
              <div class="record-item">
                <div class="record-item-title">时间</div>
                <div class="record-item-info">{{item.createTime}}</div>
              </div>
              <div class="record-item">
                <div class="record-item-title">交易内容</div>
                <div class="record-item-info">{{item.transactionContent}}</div>
              </div>
              <div class="record-item">
                <div class="record-item-title">付款金额</div>
                <div class="record-item-info">{{item.payAmount}}</div>
              </div>
              <div class="record-item">
                <div class="record-item-title">充值状态</div>
                <div class="record-item-info">{{item.status}}</div>
              </div>
            </div>
          </div>
          <div class="display-pagination" v-if="rechargeList.length > 0">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="4"
                :total="total"
                @current-change="handleCurrent"
            >
            </el-pagination>
          </div>
          <div class="display-list-null" v-if="rechargeList.length === 0">
            <div class="null-icon">
              <img src="@/assets/images/icons/null_works.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 编辑信息弹框 -->
    <el-dialog
        class="edit-dialog"
        width="500px"
        top="21vh"
        :visible.sync="editVisible"
    >
      <div class="dialog-avatar">
        <div class="dialog-avatar-img">
          <img :src="editParams.avatar" alt=""/>
          <span>修改头像</span>
          <div class="upload">
            <UploadFile ref="uploadAvatar" :width="96" :height="96" accept=".png,.jpg,.jpeg"
                        fid="1630046342280601601" @pathChange="getAvatarPath">
            </UploadFile>
          </div>
        </div>
        <div class="dialog-avatar-msg">
          <p>一个好的头像可以让更多人关注你，用于网站内展示</p>
          <p>（支持JPG、JPEG、PNG，大小不超过1M）</p>
        </div>
      </div>
      <div class="dialog-info">
        <div class="dialog-info-item">
          <div class="item">昵称</div>
          <div class="ipt">
            <input
                type="text"
                v-model="editParams.nickName"
                maxlength="10"
                placeholder="请输入昵称"
                @keyup.enter="savePersonalInfo()"
            />
          </div>
        </div>
        <div class="dialog-info-item">
          <div class="item">签名</div>
          <div class="ipt">
            <input
                type="text"
                v-model="editParams.motto"
                maxlength="30"
                placeholder="请填写个人签名（30字以内）"
                @keyup.enter="savePersonalInfo()"
            />
          </div>
        </div>
      </div>
      <div class="dialog-btn">
        <button class="dialog-btn-cancel" @click="editVisible = false">
          取消
        </button>
        <button class="dialog-btn-save" @click="savePersonalInfo()">
          保存
        </button>
      </div>
    </el-dialog>

    <!-- 创建收藏夹弹框 -->
    <el-dialog
        class="add-dialog"
        title="创建文件夹"
        :visible.sync="addVisible"
        width="500px"
        top="21vh"
        center
    >
      <div class="dialog-border"></div>
      <div class="dialog-ipt">
        <input
            type="text"
            maxlength="8"
            v-model="favoritesName"
            placeholder="名称"
            @keyup.enter="createFavorites()"
        />
      </div>
      <div class="dialog-btn">
        <button class="dialog-btn-cancel" @click="addVisible = false">
          取消
        </button>
        <button class="dialog-btn-create" @click="createFavorites()">
          创建
        </button>
      </div>
    </el-dialog>

    <!-- 修改收藏夹弹框 -->
    <el-dialog
        class="add-dialog"
        title="修改收藏夹"
        :visible.sync="modifyVisible"
        width="500px"
        top="21vh"
        center
    >
      <div class="dialog-border"></div>
      <div class="dialog-ipt">
        <input
            type="text"
            maxlength="8"
            v-model="modifyParams.name"
            placeholder="名称"
            @keyup.enter="modifyFavorites()"
        />
      </div>
      <div class="dialog-btn2">
        <div class="dialog-btn-left" @click="deleteFavorites()">删除收藏夹</div>
        <div class="dialog-btn-right">
          <button class="cancel" @click="modifyVisible = false">取消</button>
          <button class="modify" @click="modifyFavorites()">保存</button>
        </div>
      </div>
    </el-dialog>

    <!-- 编辑素材弹框 -->
    <el-dialog
        class="move-dialog"
        title="移动至收藏夹"
        :visible.sync="moveVisible"
        width="967px"
        top="21vh"
        center
    >
      <div class="move-dialog-content">
        <div class="content-left">
          <img :src="targetFavoriteImg" alt=""/>
        </div>
        <div class="content-right">
          <!-- :style="{
              border: downVisible ? '2px solid #FFF429' : '2px solid',
            }" -->
          <div
              class="content-right-file"
              @click.stop="downVisible = !downVisible"
          >
            <div class="name">{{ moveParams.targetFavoriteName }}</div>
            <div class="icon">
              <img
                  :src="
                  downVisible
                    ? require('../../assets/images/icons/pull_up.png')
                    : require('../../assets/images/icons/drop_down.png')
                "
                  alt=""
              />
            </div>
          </div>
          <div
              class="down"
              :style="{
              'overflow-y':
                favoritesList && favoritesList.length > 4 ? 'scroll' : 'none',
            }"
              v-show="downVisible"
          >
            <div
                class="down-lab"
                v-for="item in favoritesList"
                :key="item.id"
                @click.stop="onSelectFavorites(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="content-right-buttons">
            <div class="move" @click="onCancelCollection()">取消收藏</div>
            <div class="btn">
              <button class="cancel" @click="moveVisible = false">取消</button>
              <button class="modify" @click="onMoveFavorites()">保存</button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 充值弹框 -->
    <Recharge ref="recharge"></Recharge>

    <!--关注、粉丝弹出框-->
    <!--<ListPopup ref="listPopup"></ListPopup>-->
  </div>
</template>

<script>
// import ListPopup from "@/components/ListPopup/ListPopup.vue";
import NavTop from "@/components/NavTop/NavTop.vue";
import Recharge from "@/components/Recharge/Recharge.vue";
import UploadFile from "@/components/UploadFile/UploadFile.vue";
import userApi from "@/api/user.js";
import {MessageBox} from "element-ui";

export default {
  components: {
    NavTop,
    Recharge,
    UploadFile
    // ListPopup,
  },
  data() {
    return {
      tabIndex: 3, //Tab索引
      authorId: null, //作者ID
      personalInfo: "", //个人信息
      balanceInfo: "", //用户余额信息
      collectionIndex: 1, //1：该收藏夹 2：该收藏夹作品
      draftShow: false, //草稿箱显隐
      editVisible: false, //编辑信息弹框
      addVisible: false, //创建收藏夹弹框
      modifyVisible: false, //修改收藏夹弹框
      moveVisible: false, //编辑素材弹框
      downVisible: false, //收藏夹下拉框显隐
      //编辑资料参数
      editParams: {
        userId: "",
        avatar: "",
        nickName: "",
        motto: "",
      },
      favoritesName: "", //创建收藏夹名称
      //修改收藏夹参数
      modifyParams: {
        id: "",
        name: "",
        userId: this.$store.state.userId,
      },
      favoritePath: "", //当前打开的收藏夹名称
      favoriteId: "", //当前打开的收藏夹id
      // 移动至收藏夹参数
      moveParams: {
        favoriteId: "",
        materialId: "",
        targetFavoriteId: "",
        targetFavoriteName: "",
        userId: this.$store.state.userId,
      },
      targetFavoriteImg: "", //选择移动的图片
      targetFavoriteName: "", //选择移动到该文件夹
      hisWorksList: [], //ta的作品列表
      mineWorksList: [], //我的作品列表
      draftWorksList: [], //我的草稿箱
      draftCover: '', //草稿箱封面
      draftWorksLength: 0, //草稿箱数量
      downloadList: [], //下载列表
      favoritesList: [], //收藏夹列表
      worksList: [], //当前收藏夹作品列表
      orderList: [], //订单列表
      rechargeList: [], //充值列表
      total: 0, //列表总数量
      current: 1, //当前页
    };
  },
  created() {
    this.tabIndex = parseInt(window.localStorage.getItem('tabIndex'));
    if (this.tabIndex === 5 && !this.hasPerm('showings:my:works')) {
      this.tabIndex = 3;
      window.localStorage.setItem('tabIndex', 3);
    }
    this.authorId = this.$route.query.id ? this.$route.query.id : null;
    this.getUserData(); //获取用户信息
  },
  methods: {
    // 选择Tab标签
    selectTabIndex(num) {
      this.total = 0;
      this.current = 1;
      this.tabIndex = num;
      window.localStorage.setItem('tabIndex', num);
      if (this.tabIndex === 2) {
        this.getFavoritesData(); //获取收藏夹列表
      }
      if (this.tabIndex === 3) {
        this.getDownloadData(); //获取下载列表
      }
      if (this.tabIndex === 4) {
        this.getOrderData(); //获取订单列表
      }
      if (this.tabIndex === 5) {
        this.draftShow = false;
        this.getDraftWorksCount(); //获取草稿箱数量和封面
        this.getMineWorksData(); //获取我的作品列表
      }
      if (this.tabIndex === 6) {
        this.getRechargeData(); //获取我的充值记录
      }
    },
    // 获取用户信息
    getUserData() {
      userApi.postUserData({
        userId: this.$store.state.userId,
        authorId: !this.authorId ? this.$store.state.userId : this.authorId,
      }).then((res) => {
        this.personalInfo = res.data.data;
        if (!this.authorId) {
          this.getBalanceData(); //查询用户余额
          if (this.tabIndex === 2) {
            this.getFavoritesData(); //获取收藏夹列表
          }
          if (this.tabIndex === 3) {
            this.getDownloadData(); //获取下载列表
          }
          if (this.tabIndex === 4) {
            this.getOrderData(); //获取订单列表
          }
          if(this.tabIndex === 5) {
            this.getDraftWorksCount(); //获取草稿箱数量和封面
            this.getMineWorksData(); //获取我的作品列表
          }
          if(this.tabIndex === 6) {
            this.getRechargeData(); //获取我的充值记录
          }
        } else {
          this.getHisWorksList(); //获取ta的作品列表
          this.addVisitorsRecord(); //添加访问记录
        }
      });
    },
    // 添加访客记录
    addVisitorsRecord() {
      userApi.postVisitorsRecord({
        beVisitorId: this.personalInfo.authorId,
        visitorId: this.$store.state.userId,
      }).then((res) => {
        if (res.data.code === 200) {
          console.log(res.data.msg);
        } else {
          console.log(res.data.msg);
        }
      });
    },
    // 获取ta的作品列表
    getHisWorksList() {
      userApi.postHisWorksList({
        authorId: this.authorId,
        userId: this.$store.state.userId,
        size: 9,
        current: this.current,
      }).then((res) => {
        this.total = res.data.data.total;
        this.hisWorksList = res.data.data.list;
      });
    },
    // 查询余额
    getBalanceData() {
      userApi.getUserBalance({
        userId: this.$store.state.userId
      }).then(res => {
        this.balanceInfo = res.data.data;
      })
    },
    // 获取草稿箱数量和封面
    getDraftWorksCount() {
      userApi.getMineWorksList({
        authorId: this.$store.state.userId,
        size: 1,
        page: 1,
        publishStatus: 0
      }).then((res) => {
        this.draftWorksLength = res.data.data.total;
        if(res.data.data.total > 0) {
          this.draftCover = res.data.data.list[0].cover;
        }
      });
    },
    // 获取我的作品列表
    getMineWorksData() {
      userApi.getMineWorksList({
        authorId: this.$store.state.userId,
        size: 8,
        page: this.current,
        publishStatus: 1
      }).then((res) => {
        this.total = res.data.data.total;
        this.mineWorksList = res.data.data.list;
      });
    },
    // 获取草稿箱列表
    getDraftWorksList() {
      userApi.getMineWorksList({
        authorId: this.$store.state.userId,
        size: 9,
        page: this.current,
        publishStatus: 0
      }).then((res) => {
        console.log(res);
        this.total = res.data.data.total;
        this.draftWorksList = res.data.data.list;
      });
    },
    // 获取下载列表
    getDownloadData() {
      userApi.postDownloadData({
        userId: this.$store.state.userId,
        current: this.current,
        size: 3,
      }).then((res) => {
        this.total = res.data.data.total;
        this.downloadList = res.data.data.list;
      });
    },
    // 获取收藏夹列表
    getFavoritesData() {
      userApi.postFavoritesData({
        userId: this.$store.state.userId,
      }).then((res) => {
        this.favoritesList = res.data.data;
      });
    },
    // 获取收藏夹作品列表
    getCollectionData() {
      userApi.postCollectionData({
        userId: this.$store.state.userId,
        favoriteId: this.favoriteId,
        current: this.current,
        size: 9,
      }).then((res) => {
        if (res.data.data) {
          this.worksList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    // 获取订单列表
    getOrderData() {
      userApi.postOrderdata({
        userId: this.$store.state.userId,
        current: this.current,
        size: 3
      }).then((res) => {
        this.total = res.data.data.total;
        this.orderList = res.data.data.list;
      });
    },
    // 获取充值记录
    getRechargeData() {
      userApi.postRechargedata({
        userId: this.$store.state.userId,
        pageNo: this.current,
        pageSize: 4,
      }).then((res) => {
        this.total = res.data.data.total;
        this.rechargeList = res.data.data.list;
      });
    },
    // 切换分页
    handleCurrent(val) {
      this.current = val;
      if (this.tabIndex === 1) {
        this.getHisWorksList(); //获取ta的作品列表
      }
      if (this.tabIndex === 2) {
        this.getCollectionData(); //获取收藏夹作品列表
      }
      if (this.tabIndex === 3) {
        this.getDownloadData(); //获取下载列表
      }
      if (this.tabIndex === 4) {
        this.getOrderData(); //获取订单列表
      }
      if (this.tabIndex === 5) {
        if (this.draftShow) {
          this.getDraftWorksList(); // 获取草稿箱列表
        } else {
          this.getMineWorksData(); //获取我的作品列表
        }
      }
      if (this.tabIndex === 6) {
        this.getRechargeData(); //获取我的充值记录
      }
    },
    // 打开收藏夹
    openFavorites(item) {
      this.collectionIndex = 2;
      this.favoritePath = this.moveParams.targetFavoriteName = item.name;
      this.favoriteId = item.id;
      this.getCollectionData();
    },
    // 打开草稿箱
    openDraftBox() {
      this.current = 1;
      this.draftShow = true;
      this.getDraftWorksList();
    },
    // 返回我的作品列表
    onBackWorks() {
      this.current = 1;
      this.draftShow = false;
      this.getDraftWorksCount(); //获取草稿箱数量和封面
      this.getMineWorksData(); //获取我的作品列表
    },
    // 跳转素材详情页
    jumpDetailsPage(name, material) {
      let routeData = this.$router.resolve({
        path: "/home/details",
        query: {
          name,
          material,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 打开创建收藏夹弹框
    openAddDialog() {
      this.favoritesName = '';
      this.addVisible = true;
    },
    // 创建收藏夹
    createFavorites() {
      if (this.favoritesName) {
        this.addVisible = false;
        userApi
            .postCreateFavorites({
              name: this.favoritesName,
              userId: this.$store.state.userId,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message.success("创建成功");
                this.getFavoritesData(); //刷新收藏夹列表
                this.favoritesName = "";
              } else {
                this.$message.error(res.data.msg);
              }
            });
      } else {
        this.$message.error("请输入收藏夹名称");
      }
    },
    // 打开修改收藏夹弹框
    openModifyDialog(item) {
      this.modifyParams.id = item.id;
      this.modifyParams.name = item.name;
      this.modifyVisible = true;
    },
    // 修改收藏夹
    modifyFavorites() {
      if (this.modifyParams.name) {
        userApi.postModifyFavorites(this.modifyParams).then((res) => {
          this.modifyVisible = false;
          if (res.data.code === 200) {
            this.getFavoritesData(); //刷新收藏夹列表
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请输入收藏夹名称");
      }
    },
    // 删除收藏夹
    deleteFavorites() {
      MessageBox.confirm(
          "确定删除收藏夹吗？删除后收藏夹的内容会被删除。",
          "删除确认",
          {
            confirmButtonText: "删除",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).then(() => {
        userApi
            .postDeleteFavorites({
              id: this.modifyParams.id,
              userId: this.modifyParams.userId,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.modifyVisible = false;
                this.getFavoritesData(); //刷新收藏夹列表
                this.$message.success("删除成功");
              } else {
                this.$message.error(res.data.msg);
              }
            });
      });
    },
    // 返回收藏夹首页
    backCollectionIndex() {
      this.collectionIndex = 1;
      this.favoritePath = '';
      this.favoriteId = '';
      this.worksList = [];
      this.current = 1;
      this.total = 0;
      this.getFavoritesData();
    },
    // 打开移动至收藏夹弹框
    openMoveDialog(item) {
      this.moveVisible = true;
      this.downVisible = false;
      this.targetFavoriteImg = item.materialInfoVO.cover;
      this.moveParams.favoriteId = this.favoriteId;
      this.moveParams.materialId = item.materialInfoVO.materialId;
      this.moveParams.targetFavoriteId = this.favoriteId;
      this.moveParams.targetFavoriteName = this.favoritePath;
    },
    // 取消收藏
    onCancelCollection() {
      MessageBox.confirm("确定取消收藏该素材吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = JSON.parse(JSON.stringify(this.moveParams));
        delete params.targetFavoriteId;
        delete params.targetFavoriteName;
        userApi.postCancelCollection(params).then((res) => {
          if (res.data.code === 200) {
            this.getCollectionData();
            this.moveVisible = false;
            this.$message.success("取消收藏");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      });
    },
    // 选择目标收藏夹
    onSelectFavorites(item) {
      this.moveParams.targetFavoriteId = item.id;
      this.moveParams.targetFavoriteName = item.name;
      this.downVisible = false;
    },
    // 移动至收藏夹
    onMoveFavorites() {
      let params = JSON.parse(JSON.stringify(this.moveParams));
      delete params.targetFavoriteName;
      userApi.postMoveFavorites(params).then((res) => {
        if (res.data.code === 200) {
          this.getCollectionData(); //刷新当前收藏夹
          this.getFavoritesData(); //获取收藏夹列表
          this.moveVisible = false;
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    // 打开资料编辑
    openEditDialog() {
      Object.keys(this.editParams).forEach((key) => {
        this.editParams[key] = this.personalInfo[key];
      });
      this.editParams.userId = this.$store.state.userId;
      this.editVisible = true;
    },
    // 选取头像图片
    // selectAvatarImg(e) {
    //   const FILE = e.target.files[0];
    //   if (
    //       FILE.type !== "image/jpg" &&
    //       FILE.type !== "image/jpeg" &&
    //       FILE.type !== "image/png"
    //   ) {
    //     this.$message.error("选择图片格式错误");
    //   } else if (FILE.size / 1024 > 1024) {
    //     this.$message.error("选择图片超过大小限制");
    //   } else {
    //     let formData = new FormData();
    //     formData.append("file", FILE);
    //     homeApi.postUploadDoc(formData).then((res) => {
    //       if (res.data.code === 200) {
    //         this.editParams.avatar = res.data.data.url;
    //       }
    //     });
    //   }
    // },
    // 获取头像链接
    getAvatarPath(value) {
      console.log(value);
      this.editParams.avatar = value.url;
    },
    // 保存个人资料
    savePersonalInfo() {
      if (this.editParams.nickName && this.editParams.motto) {
        userApi.updateUserData(this.editParams).then((res) => {
          if (res.data.code === 200) {
            this.editVisible = false;
            this.getUserData();
            let userInfo = this.$store.state.userInfo;
            userInfo.avatar = this.editParams.avatar;
            this.$store.commit("SET_USER", userInfo);
            this.$message.success("修改成功");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else {
        this.$message.error("请输入昵称和签名");
      }
    },
    // 充值
    onRecharge() {
      this.$refs.recharge.getEffectiveData(); //充值配置列表
    },
    // 跳转上传作品页
    jumpUploadPage(id) {
      let routeData = this.$router.resolve({
        path: "/user/upload",
        query: {
          name: id===0?'上传作品':'编辑作品',
          materialId: id
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 发布
    releaseWorks(id) {
      MessageBox.confirm("确定发布该作品吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        userApi.postUpdateWorks({
          userId: this.$store.state.userId,
          id,
          publishStatus: 1
        }).then(res => {
          if(res.data.code === 200) {
            this.getDraftWorksList(); // 获取草稿箱列表
            this.$message.success("发布成功");
          }else{
            this.$message.error(res.data.msg);
          }
        })
      }).catch(() => {
        this.$message.warning("已取消");
      });
    },
    // 删除我的作品
    delworks(id) {
      MessageBox.confirm("确定删除该作品吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false
      }).then(() => {
        userApi.postDeleteWorks({
          userId: this.$store.state.userId,
          id
        }).then(res => {
          if(res.data.code === 200) {
            if (this.draftShow) {
              this.getDraftWorksList(); // 获取草稿箱列表
            } else {
              this.getMineWorksData(); //获取我的作品列表
            }
            this.$message.success(res.data.msg);
          }else{
            this.$message.error(res.data.msg);
          }
        })
      }).catch(() => {
        this.$message.warning("已取消");
      });
    }
  },
};
</script>

<style lang="less" scoped>
  @import "../../assets/css/personal.less";
</style>