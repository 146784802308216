<template>
  <el-dialog
      custom-class="recharge-dialog"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
  >
    <div class="content">
      <div class="content-close" @click="closeRechargeDialog">
        <img src="@/assets/images/btn/up_close.png" alt="">
      </div>
      <div class="content-title">充值</div>
      <div class="content-tag">选择充值金额:</div>
      <div class="content-list">
        <div class="content-list-option" :class="payamountInfo.id === item.id?'selected':''"
             v-for="item in effectiveList" :key="item.id" @click="onSelectPayamount(item)">
          <div class="option-num">×<span>{{item.quantity}}</span> 首币</div>
          <div class="option-icon">
            <img src="@/assets/images/icons/coin.png" alt="">
          </div>
          <div class="option-price">￥{{item.discountAmount}}</div>
        </div>
      </div>
      <div class="content-info">购买物品: <span>{{payamountInfo.quantity}}</span> 首币</div>
      <div class="content-payment">
        <div class="content-payment-left">
          <div class="pay-type" :class="payType === item.type?'selected':''"
               v-for="item in paymentTypeList" :key="item.type" @click="onSelectPayment(item)">
            <div class="pay-type-icon">
              <img v-if="item.name === 'alipay'" src="@/assets/images/logo/zfb_logo.png" alt="">
              <img v-if="item.name === 'wxpay'" src="@/assets/images/logo/wx_logo.png" alt="">
            </div>
            <div class="pay-type-name" v-if="item.name === 'alipay'">支付宝支付</div>
            <div class="pay-type-name" v-if="item.name === 'wxpay'">微信支付</div>
            <div class="pay-type-choose" v-if="payType === item.type">
              <img src="@/assets/images/icons/recharge_c.png" alt="">
            </div>
          </div>
        </div>
        <div class="content-payment-middle" v-if="!isLoading">
          <vue-qr :text="payCode" :size="120"></vue-qr>
        </div>
        <div class="content-payment-middle" v-if="isLoading">
          <i class="el-icon-loading"></i>
        </div>
        <div class="content-payment-right">
          <div class="amount">应付金额: <span>¥{{payamountInfo.discountAmount}}</span></div>
          <div class="amount">优惠金额: <span>¥{{payamountInfo.amount-payamountInfo.discountAmount}}</span></div>
          <div class="complete" @click.stop="onCompletePayment">已完成支付</div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VueQr from 'vue-qr';
import userApi from "@/api/user";
import {Loading} from "element-ui";
import homeApi from "@/api/home";

export default {
  name: 'Recharge',
  components: {
    VueQr
  },
  data () {
    return {
      visible: false,
      effectiveList: [], //充值配置列表
      payamountInfo: {}, //选择的金额信息
      paymentTypeList: [{
        name: 'alipay',
        type: 1
      },{
        name: 'wxpay',
        type: 2
      }], //支付方式列表
      payType: 1, //选择支付方式
      payCode: '', //二维码
      orderNo: '', //订单编号
      isLoading: false,
    }
  },
  created() {

  },
  methods: {
    // 获取充值配置列表
    getEffectiveData() {
      this.loadingInstance = Loading.service({
        lock: true,
        text: "创建订单中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      userApi.postEffectiveList({
        appId: 1
      }).then(res => {
        this.payType = 1;
        this.effectiveList = res.data.data;
        this.payamountInfo = this.effectiveList[0];
        this.createRechargeOrder(this.payamountInfo);
      })
    },
    // 创建充值订单
    createRechargeOrder(param) {
      this.isLoading = true;
      userApi.postCreateRecharge({
        amount: param.amount,
        discountAmount: param.discountAmount,
        id: param.id,
        name: param.name,
        price: param.price,
        quantity: param.quantity,
        appId: 1,
        title: param.title,
        userId: this.$store.state.userId,
        payType: this.payType
      }).then(res => {
        this.loadingInstance.close();
        if (res.data.code === 200) {
          this.visible = true;
          this.orderNo = res.data.data.tradeInfo.orderNo;
          this.payCode = res.data.data.paymentTypeList[0].payUrl;
          this.isLoading = false;
        } else {
          this.$message.error(res.data.msg);
          this.isLoading = false;
        }
      })
    },
    // 选择支付金额
    onSelectPayamount(item) {
      this.payType = 1;
      this.payamountInfo = item;
      this.createRechargeOrder(this.payamountInfo);
    },
    // 选择支付方式
    onSelectPayment(item) {
      this.payType = item.type;
      this.createRechargeOrder(this.payamountInfo);
    },
    // 验证订单
    onCompletePayment() {
      homeApi.postCheckPayment({
        orderNo: this.orderNo,
        userId: this.$store.state.userId,
        appId: 1, //订单来源
      }).then((res) => {
        if (res.data.data.isPay === 0) {
          this.$message.error("您暂未完成支付");
        }
        if (res.data.data.isPay === 1) {
          this.$message.success("支付成功");
          this.$parent.getBalanceData();
          this.visible = false;
        }
      })
    },
    // 关闭支充值弹框
    closeRechargeDialog() {
      this.visible = false;
      homeApi.postCheckPayment({
        orderNo: this.orderNo,
        userId: this.$store.state.userId,
        appId: 1, //订单来源
      }).then((res) => {
        if (res.data.data.isPay === 1) {
          this.$message.success("支付成功");
          this.$parent.getBalanceData();
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .recharge-dialog {
  width: 653px;
  height: 653px;
  background: url("../../assets/images/home/recharge_bg.png") no-repeat;
  background-size: 100% 100%;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    position: relative;

    .content {
      width: 458px;
      padding: 0 30px 30px;
      box-sizing: border-box;
      background: #1D201E;
      border-radius: 11px;
      position: absolute;
      left: 98px;
      right: 98px;
      top: 153px;

      .content-close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%,-50%);
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content-title {
        width: 100%;
        padding: 25px 0;
        box-sizing: border-box;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: #FCEE70;
      }

      .content-tag {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        color: #F9F9F9;
      }

      .content-list {
        width: 100%;
        margin-top: 15px;
        border-bottom: 1px solid #979797;
        padding-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .content-list-option {
          width: 32%;
          margin-right: 2%;
          margin-bottom: 2%;
          background: #353836;
          border-radius: 3px;
          padding-top: 10px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;

          .option-num {
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #FCEE70;

            span {
              font-size: 16px;
            }
          }

          .option-icon {
            width: 25px;
            height: 18px;
            margin: 3px auto 6px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .option-price {
            width: 100%;
            background: linear-gradient(180deg, #2B2D2B 0%, #131413 100%);
            border-radius: 0 0 3px 3px;
            padding: 5px 0;
            box-sizing: border-box;
            text-align: center;
            line-height: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #F9F9F9;
          }
        }

        .content-list-option:nth-of-type(3n) {
          margin-right: 0;
        }

        .selected {
          border: 1px solid;
          border-image: linear-gradient(180deg, rgba(255, 253, 238, 1), rgba(255, 214, 20, 1)) 1 1;
        }
      }

      .content-info {
        width: 100%;
        padding: 15px 0;
        box-sizing: border-box;
        font-size: 12px;
        font-weight: 400;
        color: #F9F9F9;

        span {
          font-size: 18px;
        }
      }

      .content-payment {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content-payment-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .pay-type {
            width: 120px;
            margin: 5px 0;
            padding: 7px 8px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            cursor: pointer;

            .pay-type-icon {
              width: 20px;
              height: 20px;
              display: flex;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .pay-type-name {
              margin-left: 6px;
              font-size: 14px;
              font-weight: 600;
              color: #979797;
            }

            .pay-type-choose {
              width: 15px;
              height: 13px;
              position: absolute;
              right: -1px;
              bottom: 2px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .selected {
            border-radius: 4px 4px 0 4px;
            border: 1px solid #FCEE70;
          }
        }

        .content-payment-middle {
          width: 87px;
          height: 87px;
          background: #FFFFFF;
          border: 1px solid #EAEAEA;
          box-sizing: border-box;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
        }

        .content-payment-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .amount {
            margin: 5px 0;
            font-size: 12px;
            font-weight: 400;
            color: #F9F9F9;

            span {
              font-size: 16px;
              color: #F51B1B;
            }
          }

          .complete {
            width: 96px;
            height: 30px;
            margin: 5px 0;
            background: #fff429;
            border-radius: 15px;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #212523;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>